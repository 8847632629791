export const environment = {
  production: true,
  apiUrl: "https://accplatformapi.pkisigning.io",
  identityUrl: "https://accidentity.pkisigning.io",
  signingApiUrl: "https://accengine.pkisigning.io",
  platformname: "Acceptatie",
  enableTaxPaymentIntegration: true,
  applicationInsightsKey: "1e3d521d-09a3-43a6-8aba-77731978beab",
  applicationFullName: "AccPlatformV2PKIsigning"
};
